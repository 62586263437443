import React from 'react';
import Text from './Text';

type Props = {
  label: string;
  options: Array<string>;
  onSelect: (value: string) => void;
  disabled?: boolean;
  textProps?: any;
  error?: string;
}

export default (props: Props) => {
  return (
    <>
      <div
        style={Object.assign(
          {}, 
          styles.container, 
          props.disabled && styles.disabled, 
          props.error && styles.error
        )}
      >
        <select
          disabled={props.disabled}
          style={styles.select}
          onChange={(event) => props.onSelect(event.target.value)}
          {...props.textProps}
        >
          <option 
            value={''}
          >
            {props.label}
          </option>
          {props.options.map((opt) => (
            <option value={opt}>{opt}</option>
          ))}
        </select>
      </div>
      {
        props.error && 
        <Text 
          variant='caption' 
          style={styles.errorLabel}
        >
          *{props.error}
        </Text>
      }
    </>
  )
}


const styles: {[key: string]: React.CSSProperties} = {
  container: {
    border: '2px solid #0066FF',
    padding: 10,
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 12,
  },
  disabled: {
    border: '2px solid lightgray',
  },
  select: {
    // border: '1px solid red',
    width: '100%',
    fontSize: 14,
    color: '#3F414E',
    fontFamily: 'Poppins-SemiBold',
    outline: 'none',
    border: 'none'
  },
  error: {
    border: '2px solid red',
  },
  errorLabel: {
    // border: '1px solid red',
    marginTop: 10,
    color: 'red',
    fontWeight: 'bold',    
  }
}