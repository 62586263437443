import React, { useEffect, useState, useCallback } from 'react';
import BottomNavigation from '../components/commons/BottomNavigation';
import Text from '../components/commons/Text';
// @ts-ignore
import DatePicker from 'react-horizontal-datepicker';
import { getAnnouncements, getSubject, getSubjects, me, updateUser } from '../utils/api';
import SelectCourseDialog from '../components/home/SelectCourseDialog';
import { COURSES, SPECIALIZATION_ID } from '../utils/constants';
import AnnouncementItem from '../components/home/AnnouncementItem';
import {
  throttle
} from 'lodash';
import {
  useMediaQuery
} from 'react-responsive';
import SideNavigation from '../components/commons/SideNavigation';
import colors from '../constants/colors';


type Props = {}

const Home = (props: Props) => {
  const isBigScreen = useMediaQuery({ minWidth: 768 });
  const hours = new Date().getHours()
  const isDayTime = hours > 6 && hours < 20;
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [user, setUser] = useState<any>({});
  const [announcements, setAnnouncements] = useState<{
    _id: string;
    title: string;
    slug: string;
    content: string;
    thumbnail: string;
    date: Date;
  }[]>(
    [],
  );
  const [subjects, setSubjects] = useState<Array<{
    _id: string;
    title: string;
  }>>();
  const [course, setCourse] = useState<string|null>(null);
  const [subject, setSubject] = useState<string|null>(null);

  const init = async () => {
    const res = await me();
    setUser(res);

    const subjectRes = await getSubjects(1, 100, SPECIALIZATION_ID);
    setSubjects(subjectRes.docs);

    const announcementRes = await getAnnouncements();
    setAnnouncements(announcementRes.docs);

    if (!res.course  || (res.course === COURSES.BSED && res.specializedSubject === undefined)) {
      setShowDialog(true);
    }
  } 

  useEffect(() => {
    init();
  }, []);

  const handleOnSubmitCourseAndSubject = throttle(async () => {
    await updateUser(user._id, {
      course: course,
      specializedSubject: subjects?.find((item) => item.title === subject)?._id
    });
    setShowDialog(false);
  }, 2000, { trailing: true }); 

  return ( 
    <>
      <SelectCourseDialog
        visible={showDialog}
        onDismiss={() => setShowDialog(false)}
        courses={[COURSES.BEED, COURSES.BSED]}
        subjects={subjects?.map((subject) => subject.title) || []}
        disabledSubjects={!course || course === COURSES.BEED}
        onSelectCourse={(value: string) => setCourse(value)}
        onSelectSubject={(value: string) => setSubject(value)}
        onSumbit={() => handleOnSubmitCourseAndSubject()}
        disabledOnSubmit={!course || (course === COURSES.BSED && !subject)}
      />
      <div
        style={Object.assign({}, styles.root, isBigScreen && styles.rootBigScreen)}
      >
        { isBigScreen &&
          (
            <div
              style={styles.sideNav}
            >
              <SideNavigation />
            </div>
          )
        }
        
        <div
          style={Object.assign({}, styles.container, isBigScreen && styles.containerBigScreen)}
        >
          <div
            style={styles.heading}
          >
            { !isBigScreen && (
              <div style={styles.logoContainer}>
                <img src={require('../assets/met-logo.png')}  style={styles.logo} />
              </div>
            )}


            <div 
              style={{
                paddingTop: 20, 
                width: 'inherit'
              }}
            >
              <Text
                variant="title"
              >
                Good {isDayTime ? 'Morning' : 'Evening'}, {`${user?.fullName || ''}`}
              </Text>
              <Text
                variant="subtitle"
              >
                We wish you have a good day
              </Text>
            </div>

            <div
              style={styles.calendarContainer}
            >
              <DatePicker />
            </div>
            
            <Text variant="title"
              style={{
                marginBottom: 10,
              }}
            >
              Announcements
            </Text>
          </div>

          <div style={styles.body}>
            <div style={styles.content}>  
              {
                announcements.map((item) => (
                  <AnnouncementItem item={item} />
                ))
              }
               {
                announcements.map((item) => (
                  <AnnouncementItem item={item} />
                ))
              }
               {
                announcements.map((item) => (
                  <AnnouncementItem item={item} />
                ))
              }
               {
                announcements.map((item) => (
                  <AnnouncementItem item={item} />
                ))
              }
               {
                announcements.map((item) => (
                  <AnnouncementItem item={item} />
                ))
              }
            </div>
          </div>

          {!isBigScreen &&  (
            <div style={styles.footer}>
              <BottomNavigation />
            </div>
          )}  
        </div>

      </div>
    </>
  )
}

const styles: {[key: string]: React.CSSProperties} =  {
  root: {
    display: 'flex',
    flexDirection: 'column',
    background: '#f4f4f4'
  },
  rootBigScreen: {
    flexDirection: 'row',
  },
  sideNav: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexGrow: 1,
    paddingTop: 20,
    background: colors.white,
    maxWidth: 300,
  },
  container: {
    // border: '1px solid green',
    minHeight: '100svh',
    maxHeight: '100svh',
    display: 'flex',
    flexDirection: 'column',
    flex: 3,
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 20, 
    paddingRight: 20,
  
  },
  containerBigScreen: {
    margin: '0 auto',
    maxWidth: 768,
  },
  heading: {
    // border: '1px solid red',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: 20,
    paddingBottom: 20,
  },
  logoContainer: {
    // border: '1px solid red',
    height: 72,
  },
  logo: {
    objectFit: 'contain',
    width: '100%',
    height: 'inherit',
  },
  body: {
    // border: '1px solid red',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    paddingLeft: 20, 
    paddingRight: 20,
    overflow: 'hidden',
    width: '100%',
  },
  bodyBigScreen: {
    width: 600,
  },
  content: {
    // display: 'flex',
    justifyContent: 'center',
    height: 'inherit',
    overflow: 'scroll',
    overflowX: 'hidden'
  },
  contentBigScreen: {
    width: 600,
  },
  footer: {
    // border: '1px solid red',
    width: '100%',
    background: colors.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  calendarContainer: {
    // border: '1px solid red',
    paddingTop: 20,
    paddingBottom: 20,
    width: '100%',
    maxWidth: 700,
    margin: '0 auto'
  },
  announcementItem: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 12,
    padding: 10,
    boxShadow: '0px 1px 5px #888',
    marginBottom: 10,
    marginTop: 20,
    // minHeight: 50
  },
  announcementItemIcon: {
    height: 50,
    objectFit: 'contain',
    marginRight: 10
  },
  announcementItemTitle: {
    // fontWeight: 600,
  },
  announcementItemSubtitle: {
    marginTop: 5
  }
}

export default Home;