import React from 'react';

type Props = {
  style?: React.CSSProperties,
  children: any,
  variant?: 'heading' | 'title' | 'subtitle' | 'caption',
};

const Text = (props: Props) => {

  const {
    style,
    variant = 'text',
    children
  } = props;

  const getVariantStyle = () => {
    switch (variant) {
      case 'heading':
        return styles.heading;
      case 'title':
        return styles.title;
      case 'subtitle':
        return styles.subtitle;
      case 'caption':
        return styles.caption;
    }
  }

  return (
    <div
      style={styles.container}
    >
      <p style={Object.assign({}, getVariantStyle(), style) }>
        {children}
      </p>
    </div>
  )
}

const styles: {[key: string]: React.CSSProperties} = {
  container: {
    // border: '1px solid red',
  },
  heading: {
    margin: 0,
    fontSize: 25,
    textAlign: 'center',
    fontFamily: 'Poppins-SemiBold'
  },
  title: {
    margin: 0,
    fontSize: 18,
    color: '#3F414E',
    fontFamily: 'Poppins-SemiBold'
  },
  subtitle: {
    margin: 0,
    fontSize: 14,
    color: '#3F414E',
    fontFamily: 'Poppins-SemiBold'
  },
  caption: {
    margin: 0,
    fontSize: 12,
    color: '#3F414E',
    fontFamily: 'Poppins-Light'
  }
};

export default Text;


