import React, { ReactNode } from 'react';
import colors from '../../constants/colors';
import {
  useMediaQuery
} from 'react-responsive';
const closeIcon = require('../../assets/icons/close.png');

type Props = {
  children?: ReactNode,
  visible: boolean;
  onClose: () => void;
}

export default (props: Props) => {
  const isBigScreen = useMediaQuery({ minWidth: 768 });
  if (!props.visible) return (null);
  return (
    <div
      style={Object.assign({}, styles.container, styles.containerBigScreen)}
    >
      <div
       style={Object.assign({}, styles.overlay, isBigScreen && styles.overlayBigScreen)}
      >
        <div
          style={{ 
            display: 'flex', 
            justifyContent: 'flex-end',
            paddingRight: 10,
            paddingTop: 10,
          }}
        >
          <img 
            src={closeIcon} 
            style={{ height: 24 }} 
            onClick={() => props.onClose()}
          />
        </div>
        <div
          style={styles.body}
        >
          <div style={styles.content}>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  )
}

const styles: {[key: string]: React.CSSProperties} = {
  container: {
    // border: '1px solid red',
    height: '100svh',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1000,
    background: colors.white,
    display: 'flex',
    flexDirection: 'column'
  },
  containerBigScreen: {
    background: 'rgba(0,0,0,0.5)',
  },
  overlay: {
    // border: '1px solid blue',
    display: 'flex', 
    justifyContent: 'flex-end',
    flexDirection: 'column',
    flexGrow: 1,
    background: colors.white,
  },
  overlayBigScreen: {
    maxWidth: 500,
    margin: '0 auto', 
    maxHeight: 600,
    marginTop: 100,
  },
  body: {
    // border: '1px solid red',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    paddingLeft: 20, 
    paddingRight: 20,
    overflow: 'hidden',
    justifyContent: 'center',
  },
  content: {
    // display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 'inherit',
    overflow: 'scroll'
  },
}