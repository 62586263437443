import React, { useEffect, useState } from 'react';
import BottomNavigation from '../components/commons/BottomNavigation';
import Text from '../components/commons/Text';
import colors from '../constants/colors';
import { getCategories, getSubscriptions, getUserCourseware, getUserCoursewares, me } from '../utils/api';
import { useNavigate, useLocation } from "react-router-dom";
// @ts-ignore
import parse from 'html-react-parser';
import {
  useMediaQuery
} from 'react-responsive';
import SideNavigation from '../components/commons/SideNavigation';

type Props = {}

export default (props: Props) => {
  const isBigScreen = useMediaQuery({ minWidth: 768 });
  const [courseware, setCourseware] = useState<{
    _id: string;
    content: string;
    title: string;
  }>();

  const [coursewares, setCoursewares] = useState<Array<{
    _id: string;
    content: string;
    title: string;
  }>>();


  const init = async () => {
    const user = await me();
    if (!user) return;
    const coursewareData = await getUserCoursewares(user._id);
    setCoursewares(coursewareData.docs);
    setCourseware(coursewareData.docs[0])
  }

  useEffect(() => {
    init();
  }, [])
  
  
  return (
    <div
      style={Object.assign({}, styles.root, isBigScreen && styles.rootBigScreen)}
    >
      { isBigScreen &&
        (
          <div
            style={styles.sideNav}
          >
            <SideNavigation />
          </div>
        )
      }
      
      <div
        style={Object.assign({}, styles.container, isBigScreen && styles.containerBigScreen)}
      >
        <div
          style={styles.heading}
        >
          <Text 
            variant='heading'
            style={styles.headingText}
          >
            Courseware
          </Text>
          <div
            style={styles.headingTabContainer}
          >
            <div
              style={styles.headingTab}
            >
              {coursewares?.map((item) => (
                <div
                  style={
                    Object.assign({}, 
                      styles.coursewareItem, 
                      (courseware && courseware._id === item._id) && styles.coursewareItemSelected
                    )
                  }
                  onClick={(() => setCourseware(item))}
                >
                  <Text
                    variant="subtitle"
                    style={Object.assign({}, (courseware && courseware._id === item._id) ? styles.coursewareItemSelectedText : {})}
                  >
                    {item.title}
                  </Text>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div style={styles.body}>
          <div style={Object.assign({}, styles.content, isBigScreen && styles.contentBigScreen)}>
            <div
              style={styles.coursewareContent}
            >
              {  courseware?.content && parse(courseware?.content) }
            </div>
          </div>
        </div>

        
        {!isBigScreen &&  (
          <div style={styles.footer}>
            <BottomNavigation />
          </div>
        )}  
      </div>
    </div>
  )
}

const styles: {[key: string]: React.CSSProperties} =  {
  root: {
    display: 'flex',
    flexDirection: 'column',
    background: '#f4f4f4'
  },
  rootBigScreen: {
    flexDirection: 'row',
  },
  sideNav: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexGrow: 1,
    paddingTop: 20,
    background: colors.white,
    maxWidth: 300,
  },
  container: {
    // border: '1px solid green',
    minHeight: '100svh',
    maxHeight: '100svh',
    display: 'flex',
    flexDirection: 'column',
    flex: 3,
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 20, 
    paddingRight: 20,
  },
  containerBigScreen: {
    margin: '0 auto',
    maxWidth: 768,
  },
  heading: {
    // border: '1px solid red',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: 20,
    paddingBottom: 20,
  },
  headingText: {
    textAlign: 'left',
    color: '#3F414E',
    paddingBottom: 10,
  },
  headingTabContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    overflowX: 'auto'
  },
  headingTab: {
    display: 'flex',
    flexDirection: 'row',
  },
  logoContainer: {
    height: 72,
  },
  logo: {
    objectFit: 'contain',
    width: '100%',
    height: 'inherit',
  },
  body: {
    // border: '1px solid red',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    paddingLeft: 20, 
    paddingRight: 20,
    overflow: 'hidden',
    width: '100%',
  },
  content: {
    // border: '1px solid red',
    // display: 'flex',
    justifyContent: 'center',
    height: 'inherit',
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  contentBigScreen: {
    // overflowY: 'hidden',
  },
  coursewareItem: {
    paddingLeft: 20, 
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 12,
    background: colors.white,
    marginRight: 10,
    display: 'flex',
    width: 200,
    overflow: 'hidden'
  },
  coursewareItemSelected: {
    background: colors.primary
  },
  coursewareItemSelectedText: {
    color: colors.white
  },
  coursewareContent: { 
    background: colors.white, 
    paddingLeft: 10, 
    paddingRight: 10, 
    borderRadius :12 
  },
  footer: {
    // border: '1px solid red',
    width: '100%',
    background: colors.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
}