import react from 'react';
import colors from '../../constants/colors';


type Props = {
  numberOfItems: number,
  currentItem: number;
}

const PageIndicator = (props: Props) => {

  const {
    numberOfItems = 3,
    currentItem
  } = props;

  return (
    <div style={styles.container}>
      {
        Array.from({ length: numberOfItems }, (_, i) => (
          <div
            style={Object.assign({}, styles.indicator, i === currentItem -1 && styles.activeIndicator)}
          />
        ))
      }
    </div>
  )
}

const styles: {[key: string]: React.CSSProperties} = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 10,
  },
  indicator: {
    height: 12,
    width: 12,
    borderRadius: 6,
    background: colors.secondary,
    margin: 5,
  },
  activeIndicator: {
    background: colors.primary,
  }
}

export default PageIndicator;

