import React, { useEffect, useState } from 'react';
import BottomNavigation from '../components/commons/BottomNavigation';
import Text from '../components/commons/Text';
import colors from '../constants/colors';
import { getCategories, getSubscriptions, me } from '../utils/api';
import { useNavigate, useLocation } from "react-router-dom";
import Avatar from 'react-avatar';
import Button from '../components/commons/Button';
// @ts-ignore
import Cookies from 'js-cookie';
import { ACCESS_TOKEN, MOBILE_OFFERING_ID } from '../utils/constants';
import {
  useMediaQuery
} from 'react-responsive';
import SideNavigation from '../components/commons/SideNavigation';

const coursewareIcon = require('../assets/account/courseware.png');
const profileIcon = require('../assets/account/profile.png');

type Props = {}

export default (props: Props) => {
  const isBigScreen = useMediaQuery({ minWidth: 768 });
  const navigate = useNavigate();
  const location = useLocation(); 

  const [user, setUser] = useState<{
    fullName: string;
    email: string;
    mobile: string;
    course: string;
  }>();
  const [subscriptions, setSubscriptions] = useState<[{
    offering: {
      _id: string
    },
    endDate: Date,
  }]>();

  const init = async () => {
    const userData = await me();
    setUser(userData);

    const subscriptionsData = await getSubscriptions(userData._id);
    setSubscriptions(subscriptionsData);
    // console.log (subscriptionsData);  
  }

  const getMobileSubscription = () => {
    if (!subscriptions || subscriptions.length < 1) return null;
    const subscription = subscriptions.find((i) => i.offering._id === MOBILE_OFFERING_ID);
    return subscription;
  }

  const handleOnPressLogout = () => {
    Cookies.remove(ACCESS_TOKEN);
    navigate('/');
  }

  useEffect(() => {
    init();
  }, [])
  
  
  return (
    <div
      style={Object.assign({}, styles.root, isBigScreen && styles.rootBigScreen)}
    >
      { isBigScreen &&
        (
          <div
            style={styles.sideNav}
          >
            <SideNavigation />
          </div>
        )
      }
      
      <div
        style={Object.assign({}, styles.container, isBigScreen && styles.containerBigScreen)}
      >
        <div
          style={styles.heading}
        >
          <Text 
            variant='heading'
            style={{
              textAlign: 'left',
              color: '#3F414E',
              paddingBottom: 10,
            }}
          >
            My Profile
          </Text>
        </div>

        <div style={styles.body}>
          <div style={Object.assign({}, styles.content, isBigScreen && styles.contentBigScreen)}>
            <div
              style={{
                // border: '1px solid red',
                display: 'flex',
                justifyContent: 'center',
                paddingTop: 61,
              }}
            >
              <Avatar name={user?.fullName} round={true} />
            </div>
            <Text 
              variant='title'
              style={{ textAlign: 'center', marginTop: 10, marginBottom: 10, }}
            >
              {user?.fullName}
            </Text>
            <Text
              variant='subtitle'
              style={{ textAlign: 'center'}}
            >
              {`Subscription: ${getMobileSubscription() ? 'Premium' : 'Free'}`}
            </Text>
            <div
              style={styles.infoSection}
            >
              <Text
                variant='subtitle'
              >
                Course: {user?.course || 'N/A'}
              </Text>
            </div>
            <div
              style={styles.infoSection}
            >
              <Text
                variant='subtitle'
              >
                Email: {user?.email}
              </Text>
            </div>
            <div
              style={styles.infoSection}
            >
              <Text
                variant='subtitle'
              >
                Mobile Number: {user?.mobile}
              </Text>
            </div>
            {
              getMobileSubscription() &&
              <div
              style={styles.infoSection}
            >
              <Text
                variant='subtitle'
              >
                Subscription Ends: {new Date(getMobileSubscription()?.endDate || '').toLocaleDateString()}
              </Text>
            </div>
            }
            <Button
              variant='secondary'
              title="Logout"
              style={{
                marginTop: 20,
                marginBottom: 20,
              }}
              onButtonPress={() => handleOnPressLogout()}
            />
          </div>
        </div>

        {!isBigScreen &&  (
          <div style={styles.footer}>
            <BottomNavigation />
          </div>
        )}  
      </div>
    </div>
  )
}

const styles: {[key: string]: React.CSSProperties} =  {
  root: {
    display: 'flex',
    flexDirection: 'column',
    background: '#f4f4f4'
  },
  rootBigScreen: {
    flexDirection: 'row',
  },
  sideNav: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexGrow: 1,
    paddingTop: 20,
    background: colors.white,
    maxWidth: 300,
  },
  container: {
    // border: '1px solid green',
    minHeight: '100svh',
    maxHeight: '100svh',
    display: 'flex',
    flexDirection: 'column',
    flex: 3,
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 20, 
    paddingRight: 20,
  
  },
  containerBigScreen: {
    margin: '0 auto',
    maxWidth: 768,
  },
  heading: {
    // border: '1px solid red',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: 20,
    paddingBottom: 20,
  },
  logoContainer: {
    height: 72,
  },
  logo: {
    objectFit: 'contain',
    width: '100%',
    height: 'inherit',
  },
  body: {
    // border: '1px solid red',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    paddingLeft: 20, 
    paddingRight: 20,
    overflow: 'hidden',
    width: '100%',
  },
  content: {
    // display: 'flex',
    justifyContent: 'center',
    height: 'inherit',
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  contentBigScreen: {
    // overflowY: 'hidden',
  },
  navContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    // border: '1px solid red',
    justifyContent: 'center',
    height: 205,
    borderRadius: 12,
  },
  navTitle: {
    color: colors.white,
    textAlign: 'center'
  },
  navIcon: {
    height: 82,
    objectFit: 'contain'
  },
  category: { 
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 24,
    paddingBottom: 24,
    paddingRight: 20,
    paddingLeft: 20,
    background: '#E2EFFB',
    borderRadius: 12,
    marginBottom: 10,
  },
  categoryIcon: {
    height: 50,
    width: 50,
    marginRight: 14,
  },
  infoSection: {
    display: 'flex',
    background: '#E1F0FE',
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 18,
    paddingRight: 18,
    borderRadius: 12,
    marginTop: 20,
  },
  footer: {
    // border: '1px solid red',
    width: '100%',
    background: colors.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
}