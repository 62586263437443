import React, { useEffect, useState } from 'react';
import { getMaterial, getOtherResource, me } from '../utils/api';
import BottomNavigation from '../components/commons/BottomNavigation';
import Text from '../components/commons/Text';
import { useParams } from "react-router-dom";
// @ts-ignore
import { Document } from 'react-pdf'
import {
  useMediaQuery
} from 'react-responsive';
import SideNavigation from '../components/commons/SideNavigation';
import colors from '../constants/colors';
type Props = {

}

export default (props: Props) => {
  const isBigScreen = useMediaQuery({ minWidth: 768 });
  const {
    otherResource: otherResourceId
  } = useParams();

  const [otherResource, setOtherResource] = useState<{
    _id: string,
    title: string,
    description: string,
    link: string,
  }>();
  const [numPages, setNumPages] = useState<number>();

  const init = async () => {
    if (!otherResourceId) return;
    const userData = await me();
    const otherResource = await getOtherResource(userData._id, otherResourceId);
    setOtherResource(otherResource);
    // console.log(JSON.stringify(otherResource))
  }
  
  useEffect(() => {
    init();
  }, [])


  return (
    <div
      style={Object.assign({}, styles.root, isBigScreen && styles.rootBigScreen)}
    >
      { isBigScreen &&
        (
          <div
            style={styles.sideNav}
          >
            <SideNavigation />
          </div>
        )
      }
      <div
        style={styles.container}
      >
        <div
          style={styles.heading}
        >
          { !isBigScreen && (
            <div style={styles.logoContainer}>
              <img src={require('../assets/met-logo.png')}  style={styles.logo} />
            </div>
          )}
        </div>

        <div style={styles.body}>
          <div style={styles.content}>
            { otherResource?.link && (
              <iframe 
                src={`${otherResource.link}#toolbar=0`}
                style={{
                  width: '100%',
                  height: '100%',
                }}
              />
            )}
          </div>
        </div>

        {!isBigScreen &&  (
          <div style={styles.footer}>
            <BottomNavigation />
          </div>
        )}  
      </div>
    </div>
  )
}

const styles: {[key: string]: React.CSSProperties} =  {
  root: {
    display: 'flex',
    flexDirection: 'column',
    background: '#f4f4f4'
  },
  rootBigScreen: {
    flexDirection: 'row',
  },
  sideNav: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexGrow: 1,
    paddingTop: 20,
    background: colors.white,
    maxWidth: 300,
  },
  container: {
    // border: '1px solid green',
    minHeight: '100svh',
    maxHeight: '100svh',
    display: 'flex',
    flexDirection: 'column',
    flex: 3,
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 20, 
    paddingRight: 20,
  
  },
  containerBigScreen: {
    margin: '0 auto',
    maxWidth: 768,
  },
  heading: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: 20,
    paddingBottom: 20,
  },
  logoContainer: {
    height: 72,
  },
  logo: {
    objectFit: 'contain',
    width: '100%',
    height: 'inherit',
  },
  body: {
    // border: '1px solid blue',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    paddingLeft: 20, 
    paddingRight: 20,
    overflow: 'hidden',
    width: '100%',
    height: 700,
  },
  content: {
    // border: '1px solid red',
    // display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 'inherit',
    overflowY: 'hidden',
    overflowX: 'hidden'
  },
  categoryContainer: {
    // border: '1px solid red',
    borderRadius: 12,
    background: '#0039CC',
    paddingBottom: 22,
    paddingTop: 22,
    paddingRight: 25,
    paddingLeft: 25,
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  categoryIcon: {
    height: 45,
    objectFit: 'contain',
    marginRight: 20,
  },

  item: { 
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 24,
    paddingBottom: 24,
    paddingRight: 20,
    paddingLeft: 20,
    background: '#E2EFFB',
    borderRadius: 12,
    marginBottom: 10,
  },
  itemIcon: {
    height: 80,
    // width: 162,
    objectFit: 'contain',
    borderRadius: 12,
    marginRight: 10,
  },
  footer: {
    // border: '1px solid red',
    width: '100%',
    background: colors.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
  }
}