import React, { useEffect, useState } from 'react';
import BottomNavigation from '../components/commons/BottomNavigation';
import Text from '../components/commons/Text';
import { getCategories } from '../utils/api';
import { useNavigate, useLocation } from "react-router-dom";
import {
  useMediaQuery
} from 'react-responsive';
import SideNavigation from '../components/commons/SideNavigation';
import colors from '../constants/colors';

const coursewareIcon = require('../assets/account/courseware.png');
const profileIcon = require('../assets/account/profile.png');

type Props = {}

export default (props: Props) => {
  const isBigScreen = useMediaQuery({ minWidth: 768 });
  const navigate = useNavigate();
  const location = useLocation(); 

  const navigations = [{
    title: 'My Profile',
    icon: profileIcon,
    path: 'profile',
    color: '#06F',
  }, {
    title: 'Courseware',
    icon: coursewareIcon,
    path: 'courseware',
    color: '#E52535',
  }];
  
  return (
    <div
      style={Object.assign({}, styles.root, isBigScreen && styles.rootBigScreen)}
    >
      { isBigScreen &&
        (
          <div
            style={styles.sideNav}
          >
            <SideNavigation />
          </div>
        )
      }
      
      <div
        style={Object.assign({}, styles.container, isBigScreen && styles.containerBigScreen)}
      >
        <div
          style={styles.heading}
        >
          { !isBigScreen && (
            <div style={styles.logoContainer}>
              <img src={require('../assets/met-logo.png')}  style={styles.logo} />
            </div>
          )}
        </div>

        <div style={styles.body}>
          <div style={Object.assign({}, styles.content, isBigScreen && styles.contentBigScreen)}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              {navigations.map((item, index) => (
                <div
                  key={index}
                  style={
                    Object.assign({}, styles.navContainer, {
                      background: item.color,
                    }, index % 2 === 0 ? {
                      marginRight: 10
                    } : {
                      marginLeft: 10
                    })
                  }
                  onClick={() => {
                    navigate(location.pathname + '/' + item.path)
                  }}
                >
                  <img
                    src={item.icon}
                    style={styles.navIcon}
                  />
                  <Text
                    variant="title"
                    style={styles.navTitle}
                  >
                    {item.title}
                  </Text>
                </div>
              ))}
            </div>
          </div>
        </div>

        {!isBigScreen &&  (
          <div style={styles.footer}>
            <BottomNavigation />
          </div>
        )}  
      </div>
    </div>
  )
}

const styles: {[key: string]: React.CSSProperties} =  {
  root: {
    display: 'flex',
    flexDirection: 'column',
    background: '#f4f4f4'
  },
  rootBigScreen: {
    flexDirection: 'row',
  },
  sideNav: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexGrow: 1,
    paddingTop: 20,
    background: colors.white,
    maxWidth: 300,
  },
  container: {
    // border: '1px solid green',
    minHeight: '100svh',
    maxHeight: '100svh',
    display: 'flex',
    flexDirection: 'column',
    flex: 3,
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 20, 
    paddingRight: 20,
  
  },
  containerBigScreen: {
    margin: '0 auto',
    maxWidth: 768,
  },
  heading: {
    // border: '1px solid red',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: 20,
    paddingBottom: 20,
  },
  logoContainer: {
    height: 72,
  },
  logo: {
    objectFit: 'contain',
    width: '100%',
    height: 'inherit',
  },
  body: {
    // border: '1px solid red',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    paddingLeft: 20, 
    paddingRight: 20,
    overflow: 'hidden',
    width: '100%',
  },
  content: {
    // display: 'flex',
    justifyContent: 'center',
    height: 'inherit',
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  contentBigScreen: {
    overflowY: 'hidden',
  },
  navContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    // border: '1px solid red',
    justifyContent: 'center',
    height: 205,
    borderRadius: 12,
  },
  navTitle: {
    color: colors.white,
    textAlign: 'center'
  },
  navIcon: {
    height: 82,
    objectFit: 'contain'
  },
  category: { 
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 24,
    paddingBottom: 24,
    paddingRight: 20,
    paddingLeft: 20,
    background: '#E2EFFB',
    borderRadius: 12,
    marginBottom: 10,
  },
  categoryIcon: {
    height: 50,
    width: 50,
    marginRight: 14,
  },
  footer: {
    // border: '1px solid red',
    width: '100%',
    background: colors.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
}