import React from 'react';
import Text from '../commons/Text';
import moment from 'moment';
import colors from '../../constants/colors';

const thumbnailIcon = require('../../assets/met-thumbnail.png');

type Props = {
  item: {
    title: string;
    date: Date;
  }
};

export default (props: Props) => {
  return (
    <div
      style={styles.announcementItem}
    >
      <img 
        src={thumbnailIcon}
        style={styles.announcementItemIcon}
      />
      <div>
        <Text variant="subtitle"
          style={styles.announcementItemTitle}
        >
          {props.item.title}
        </Text>
        <Text variant="caption"
          style={styles.announcementItemSubtitle}
        >
          {moment(props.item.date).format('LL')}
        </Text>
      </div>
    </div>
  )
}

const styles: {[key: string]: React.CSSProperties} =  {
  announcementItem: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 12,
    padding: 10,
    marginBottom: 10,
    marginTop: 20,
    background: colors.white
    // minHeight: 50
  },
  announcementItemIcon: {
    height: 50,
    objectFit: 'contain',
    marginRight: 10
  },
  announcementItemTitle: {
    // fontWeight: 600,
  },
  announcementItemSubtitle: {
    marginTop: 5
  }
}