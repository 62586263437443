import React from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import colors from '../../constants/colors';
type Props = {}

export default (props: Props) => {

  const home = require('../../assets/icons/home.png');
  const lectures = require('../../assets/icons/lectures.png');
  const others = require('../../assets/icons/others.png');
  const profile = require('../../assets/icons/profile.png');

  const navigate = useNavigate();
  const location = useLocation();

  // console.log(location.pathname);

  const getIcons = [{
    icon: home,
    label: 'Home',
    path: 'home',
  }, {
    icon: lectures,
    label: 'Lectures',
    path: 'lecture-categories',
  }, {
    icon: others,
    label: 'Others',
    path: 'other-resources',
  }, {
    icon: profile,
    label: 'Account',
    path:'account'
  }];
  

  return (
    <div style={styles.container}>
      {
        getIcons.map((nav, index) => (
        <div 
          key={index}
          style={styles.navigationContainer }
          onClick={() => {
            navigate("/" + nav.path);
          }}
        > 
          <img src={nav.icon} 
            style={Object.assign({}, styles.navigationIcon, location.pathname.includes(nav.path) &&  styles.navigationActive) } 
          />
          <p style={Object.assign({}, styles.navigationLabel, location.pathname.includes(nav.path) && styles.navigationActive)}>
            {nav.label}
          </p>
        </div>
        ))
      }
    </div>
  )
}

const styles: {[key: string]: React.CSSProperties} = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // borderTop: '1px solid gray',
    paddingTop: 10,
    // border: '1px solid red',
  },
  navigationContainer: {
    // height: 78,
    // border: '1px solid red',
  },
  navigationIcon: {
    // border: '1px solid red',
    objectFit: 'contain',
    height: 22,
    width: '100%',
  },
  navigationLabel: {
    margin: 0,
    // fontWeight: '700',
    fontSize: 12,
    color: '#969BB2',
    fontFamily: 'Poppins-Black'
  },
  navigationActive: {
    filter: 'saturate(30)',
  }
}