import React, { useState } from 'react';
import Button from '../components/commons/Button';
import Text from '../components/commons/Text';
import { useNavigate } from "react-router-dom";
import {
  useMediaQuery
} from 'react-responsive';
import PageIndicator from '../components/commons/PageIndicator';
import colors from '../constants/colors';

const slide1 = require('../assets/onboarding/slide-1.png');
const slide2 = require('../assets/onboarding/slide-2.png');
const slide3 = require('../assets/onboarding/slide-3.png');

type Props = {};

const Onboarding = (props: Props) => {
  const isBigScreen = useMediaQuery({ minWidth: 768 });
  const navigate = useNavigate();
  const [slideCounter, setSlideCounter] = useState(1);

  const getSlideContent = () => {
    if (slideCounter === 1) {
      return {
        img: slide1,
        content: `Welcome \n Ma'am Sir!`
      }
    } else if (slideCounter === 2) {
      return {
        img: slide2,
        content: 'Level up your Teaching Skills'
      }
    } else if (slideCounter === 3) {
      return {
        img: slide3,
        content: 'Enhance you Review Experience'
      }
    }
  }

  const handleOnNextButtonPress = () => {
    const newSlideCounter = slideCounter + 1;
    if (newSlideCounter > 3) {
      return navigate('/home');
    } else {
      setSlideCounter(newSlideCounter)
    }
  }

  return (
    <div
      style={Object.assign({}, styles.container, isBigScreen && styles.containerBigScreen)}
    >
      <div style={styles.content}>
        <div
          style={styles.slide}
        >
          <img src={getSlideContent()?.img} 
            style={styles.slideImage}
          />
        </div>
        <Text>
          {getSlideContent()?.content}
        </Text>
        <PageIndicator 
          currentItem={slideCounter}
          numberOfItems={3}
        />
      </div>
      <div style={styles.footer}>
        <div style={styles.divider} />
        <div
           style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button 
            title="Next"
            variant="secondary"
            style={{ width: 127 }}
            onButtonPress={handleOnNextButtonPress}
          />
        </div>
      </div>
    </div>
  )
}

const styles: {[key: string]: React.CSSProperties} = {
  container: {
    // border: '1px solid green',
    minHeight: '100svh',
    maxHeight: '100svh',
    display: 'flex',
    flexDirection: 'column',
    flex: 3,
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 20, 
    paddingRight: 20,
  },
  containerBigScreen: {
    margin: '0 auto',
    maxWidth: 768,
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    // border: '1px solid red',
    flexDirection: 'column',
    paddingLeft: 20, 
    paddingRight: 20,
    overflow: 'hidden',
    justifyContent: 'center'
  },
  slide: {
    height: 347
  },
  slideImage: {
    objectFit: 'contain',
    width: '100%',
  },
  footer: {
    // border: '1px solid red',
    width: '100%',
    background: colors.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  divider: {
    // border: '1px solid red',
    borderTop: '1px solid #969BB2',
    height: 20,
  }

}

export default Onboarding;