import React from 'react';
import colors from '../../constants/colors';
import typography from '../../constants/typography';

type Props = {
  style?: React.CSSProperties,
  title: string,
  variant?: 'primary' | 'secondary',
  onButtonPress?: () => void;
  disabled?: boolean;
}

const Button = (props: Props) => {

  let {
    style,
    title, 
    variant = 'primary',
    onButtonPress,
    disabled = false,
  } = props;
  return (
    <div
      style={Object.assign({}, styles.container, variant === 'secondary' && styles.btnSecondary, style, props.disabled && styles.disabled)}
      onClick={onButtonPress}
      
    >
      <p style={Object.assign({}, styles.title, variant === 'secondary' && styles.titleSecondary)}>
        {title}
      </p>
    </div>
  )
}

const styles: {[key: string]: React.CSSProperties} = {
  container: {
    // border: '1px solid red',
    backgroundColor: colors.primary,
    borderRadius: 12,
    padding: 23,
    paddingBottom: 16,
    paddingTop: 16,
    // height: 40,
    // justifyContent: 'center',
  },
  title: {
    ...typography.button,
    textAlign: 'center',
    margin: 0,
    // color: colors.secondary
    color: 'white',
  },
  btnSecondary: {
    backgroundColor: '#F8F8F8',
  },
  titleSecondary: {
    color: '#3F414E'
  },
  disabled: {
    pointerEvents: 'none',
    backgroundColor: colors.lightGrey,
  }
}

export default Button;