import React, { useEffect, useState } from 'react';
import BottomNavigation from '../components/commons/BottomNavigation';
import Text from '../components/commons/Text';
import colors from '../constants/colors';
import { getCategories, me } from '../utils/api';
import { useNavigate, useLocation } from "react-router-dom";
import { COURSES, SPECIALIZATION_ID } from '../utils/constants';
import {
  useMediaQuery
} from 'react-responsive';
import SideNavigation from '../components/commons/SideNavigation';
const categoryIcon = require('../assets/icons/category.png');

type Props = {}

export default (props: Props) => {
  const isBigScreen = useMediaQuery({ minWidth: 768 });
  const navigate = useNavigate();
  const location = useLocation(); 

  const [cateogries, setCategories] = useState([{
    _id: '',
    title: ''
  }]);

  const init = async () => {
    const user = await me();
    const data = await getCategories(1, 100, 'other-resources');
    setCategories(user.course === COURSES.BEED ? data.docs.filter((item: any) => item._id !== SPECIALIZATION_ID) : data.docs);
  }

  useEffect(() => {
    init();
  }, [])

  return (
    <div
      style={Object.assign({}, styles.root, isBigScreen && styles.rootBigScreen)}
    >
      { isBigScreen &&
        (
          <div
            style={styles.sideNav}
          >
            <SideNavigation />
          </div>
        )
      }
       <div
        style={Object.assign({}, styles.container, isBigScreen && styles.containerBigScreen)}
      >
        <div
          style={styles.heading}
        >
          { !isBigScreen && (
            <div style={styles.logoContainer}>
              <img src={require('../assets/met-logo.png')}  style={styles.logo} />
            </div>
          )}

          <Text variant="heading"
            style={{
              marginBottom: 10,
              textAlign: 'left',
              marginTop: 20,
              color: colors.primary
            }}
          >
            Categories
          </Text>
        </div>

        <div style={styles.body}>
          <div style={styles.content}>
            {cateogries.map((category) => (
              <div
                key={category._id}
                style={styles.category}
                onClick={() => {
                  navigate(`${location.pathname}/${category._id}/subjects`);
                }}
              >
                <img src={categoryIcon} 
                  style={styles.categoryIcon}
                />
                <Text 
                  variant={'title'}
                >
                    {category.title} 
                </Text>
              </div>
            ))}
          </div>
        </div>

        {!isBigScreen &&  (
          <div style={styles.footer}>
            <BottomNavigation />
          </div>
        )}  
      </div>
    </div>
  )
}

const styles: {[key: string]: React.CSSProperties} =  {
  root: {
    display: 'flex',
    flexDirection: 'column',
    background: '#f4f4f4'
  },
  rootBigScreen: {
    flexDirection: 'row',
  },
  sideNav: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexGrow: 1,
    paddingTop: 20,
    background: colors.white,
    maxWidth: 300,
  },
  container: {
    minHeight: '100svh',
    maxHeight: '100svh',
    display: 'flex',
    flexDirection: 'column',
    flex: 3,
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 20, 
    paddingRight: 20,
  },
  containerBigScreen: {
    margin: '0 auto',
    maxWidth: 768,
  },
  heading: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: 20,
    paddingBottom: 20,
  },
  logoContainer: {
    height: 72,
  },
  logo: {
    objectFit: 'contain',
    width: '100%',
    height: 'inherit',
  },
  body: {
    // border: '1px solid red',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    paddingLeft: 20, 
    paddingRight: 20,
    overflow: 'hidden',
    width: '100%',
  },
  bodyBigScreen: {
    width: 600,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 'inherit',
    overflow: 'scroll',
    overflowX: 'hidden'
  },
  category: { 
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 24,
    paddingBottom: 24,
    paddingRight: 20,
    paddingLeft: 20,
    background: '#E2EFFB',
    // background: '#08A6FF',
    borderRadius: 12,
    marginBottom: 10,
  },
  categoryIcon: {
    height: 50,
    width: 50,
    marginRight: 14,
  },
  footer: {
    // border: '1px solid red',
    width: '100%',
    background: colors.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
  }
}