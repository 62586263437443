import React, { useCallback, useEffect, useContext, useState } from 'react';
import Header from '../components/commons/Header';
import Button from '../components/commons/Button';
import TextInput from '../components/commons/TextInput';
import {
  throttle
} from 'lodash';
import {
  mustBeValidEmail,
  mustNotBeEmptyOrSpace,
  mustBeValidMobileNumber,
  mustBePasswordLength,
} from '../utils/validator';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
// @ts-ignore
import Cookies from 'js-cookie';
import {
  getSubjects,
  register as registerUser,
} from '../utils/api';
import { ACCESS_TOKEN, COURSES, SPECIALIZATION_ID } from '../utils/constants';
import Text from '../components/commons/Text';
import colors from '../constants/colors';
import {
  useMediaQuery
} from 'react-responsive';
import Select from '../components/commons/Select';

type Props = {}

type Inputs = {
  fullName: string
  email: string
  mobileNumber: string,
  course: string,
  subject: string,
  password: string
  confirmPassword: string,
}

const Register = (props: Props) => {
  const isBigScreen = useMediaQuery({ minWidth: 768 });
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm<Inputs>();
  const [subjects, setSubjects] = useState<Array<any>>();
  const [registrationError, setRegistrationError] = useState<string>('');

  const init = async () => {
    const subjectRes = await getSubjects(1, 100, SPECIALIZATION_ID);
    setSubjects(subjectRes.docs);
  }

  useEffect(() => {
    init();
  }, [])

  const watchCourse = watch("course", "")

  
  const onSubmit: SubmitHandler<Inputs> = useCallback(throttle(async (data) => {
    
    try {
      let payload: {
        fullName: string;
        email: string;
        mobile: string;
        password: string;
        course: string;
        specializedSubject?: string;
      }  = {
        fullName: data.fullName,
        email: data.email,
        mobile: data.mobileNumber,
        password: data.password,
        course: data.course
      };
  
  
      if (data.subject) {
        payload.specializedSubject = subjects?.find((item) => {
          const result = item.title === data.subject ? true: false;
          return result;
        })?._id
      }
      
      const response = await registerUser(payload);
  
      Cookies.set(ACCESS_TOKEN, response.token);
      
      navigate('/onboarding');
    } catch (e: any) {
      
      switch(e.response.status) {
        case 409: 
          setRegistrationError('Email / Mobile number already taken');
          break; 

        default: 
          setRegistrationError('Unknown error occured, please try again');
      }
    }

  }, 2000, { trailing: true }), []) 


  return (
    <div
      style={Object.assign({}, styles.container, isBigScreen && styles.containerBigScreen)}
    >
      <div style={styles.heading}>
        <div style={styles.logoContainer}>
          <img src={require('../assets/met-logo.png')}  style={styles.logo} />
        </div>
        <Text 
          variant='heading'
          style={{
            textAlign: 'left',
            color: '#3F414E',
          }}
        >
          Register Your Account
        </Text>
      </div>
     
      <div style={styles.body}>
        <div style={Object.assign({}, styles.content, isBigScreen && styles.contentBigScreen)}>
          <div
             style={{
              paddingTop: 20,
              width: '100%',
            }}
          >
            { registrationError && (
              <Text
                variant="subtitle"
                style={{
                  textAlign: 'center',
                  color: 'red'
                }}
              >
                {registrationError}
              </Text>
            )}
            
            <TextInput 
              textProps={{
                placeholder: 'Full Name',
                ...register(
                  "fullName", { 
                    validate: {
                      mustNotBeEmptyOrSpace,
                    }
                  },
                ),
              }}
              error={errors.fullName?.message}
            />
            <TextInput 
              textProps={{
                placeholder: 'Email',
                ...register(
                  "email", { 
                    validate: {
                      mustNotBeEmptyOrSpace,
                      mustBeValidEmail,
                    }
                  },
                ),
              }}
              error={errors.email?.message}
            />
            <TextInput 
              textProps={{
                placeholder: 'Mobile Number (09XXXXXXXXX)',
                maxLength: 11,
                ...register(
                  "mobileNumber", { 
                    validate: {
                      mustNotBeEmptyOrSpace,
                      mustBeValidMobileNumber
                    }
                  },
                ),
              }}
              error={errors.mobileNumber?.message}
            />
            <Select
              label={'Course'}
              options={[COURSES.BEED, COURSES.BSED]}
              onSelect={(value: any) => {}}
              textProps={{
                ...register(
                  "course", { 
                    validate: {
                      mustNotBeEmptyOrSpace,
                    }
                  },
                ),
              }}        
              error={errors.course?.message}
            />
            {watchCourse === COURSES.BSED && 
                <Select
                label={'Specialization'}
                options={subjects?.map((subject) => subject.title) || []}
                onSelect={(value: any) => {}}
                error={errors.subject?.message}
                textProps={{
                  ...register(
                    "subject", { 
                      validate: {
                        mustNotBeEmptyOrSpace,
                      }
                    },
                  ),
                }}        
              />
            }
            
            <TextInput 
              textProps={{
                type: 'password',
                placeholder: 'Password',
                ...register(
                  "password", { 
                    validate: {
                      mustNotBeEmptyOrSpace,
                      mustBePasswordLength
                    }
                  },
                ),
              }}
              error={errors.password?.message}
            />
            <TextInput 
              textProps={{
                type: 'password',
                placeholder: 'Confirm Password',
                ...register(
                  "confirmPassword", { 
                    validate: {
                      mustNotBeEmptyOrSpace,
                      mustBeSameWithPassword: (val:string) => val === getValues('password') || 'The value should be same with Password'
                    }
                  },
                ),
              }}
              error={errors.confirmPassword?.message}
            />
          </div>
        </div>
      </div>

      <div style={styles.footer}>
        <div style={styles.divider} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button 
            style={{
              width: 127
            }}
            title={'Next'} 
            variant={'secondary'}
            onButtonPress={handleSubmit(onSubmit)}
          />
        </div>
      </div>
    </div>
  )
}

const styles: {[key: string]: React.CSSProperties} = {
  container: {
    // border: '1px solid green',
    minHeight: '100svh',
    maxHeight: '100svh',
    display: 'flex',
    flexDirection: 'column',
    flex: 3,
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 20, 
    paddingRight: 20,
  },
  containerBigScreen: {
    margin: '0 auto',
    maxWidth: 768,
  },
  heading: {
    // border: '1px solid red',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 20,
  },
  logoContainer: {
    height: 72,
  },
  logo: {
    objectFit: 'contain',
    width: '100%',
    height: 'inherit',
  },
  body: {
    // border: '1px solid red',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    paddingLeft: 20, 
    paddingRight: 20,
    overflow: 'hidden',
    width: '100%',
  },
  content: {
    justifyContent: 'center',
    height: 'inherit',
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  contentBigScreen: {
    overflowY: 'hidden',
  },
  footer: {
    // border: '1px solid red',
    width: '100%',
    background: colors.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  divider: {
    // border: '1px solid red',
    borderTop: '1px solid #969BB2',
    height: 20,
  }
}

export default Register;