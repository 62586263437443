const mustNotBeEmptyOrSpace = (val: string) => (!!val && !!val.trim()) || 'This field is required';
const mustBeValidEmail = (val: string) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val) || 'The email is invalid';
const mustBeValidMobileNumber = (val: string) => /^\d{11}$/.test(val) || 'Invalid mobile number';
const mustBePasswordLength = (val: string) => val.length >= 8 || 'Password is too short';

export {
  mustNotBeEmptyOrSpace,
  mustBeValidEmail,
  mustBeValidMobileNumber,
  mustBePasswordLength
}