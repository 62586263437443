import axios from 'axios';
// @ts-ignore
import Cookies from 'js-cookie';
import {
  ACCESS_TOKEN
} from '../utils/constants';

const BASE_URL = 'https://d1rfjtvw83wlr4.cloudfront.net';
// const BASE_URL = 'http://localhost:8000';
const BASE_PATH = `${BASE_URL}/api/v2`;

export const register = async (params: {
  fullName: string;
  email: string;
  mobile: string;
  password: string;
  course: string;
  specializedSubject?: string;
}) => {
  try {
    const res = await axios.post(`${BASE_PATH}/users/register`, params, {});
    return res.data;
  } catch (e) {
    throw(e);
  }
}


export const login = async (email: string, password: string) => {
  try {
    const res = await axios.post(`${BASE_PATH}/users/sign-in`, { email, password }, {});
    return res.data;
  } catch (e) {
    throw(e);
  }
  
}

export const me = async () => {
  try {
    const res = await axios.get(`${BASE_PATH}/users/me`, {
      headers:{
        Authorization: `Bearer ${Cookies.get(ACCESS_TOKEN)}`
      }
    });
    return res.data;
  } catch (e) {
    throw(e);
  }
}

export const updateUser = async (userId: string, params: any) => {
  try {
    const res = await axios.put(`${BASE_PATH}/users/${userId}`, params, {
      headers:{
        Authorization: `Bearer ${Cookies.get(ACCESS_TOKEN)}`
      }
    });
    return res.data;
  } catch (e) {
    throw(e);
  }
}

export const getAnnouncements = async () => {
  try {
    const res = await axios.get(`${BASE_PATH}/announcements`, {
      headers:{
        Authorization: `Bearer ${Cookies.get(ACCESS_TOKEN)}`
      }
    });
    return res.data;
  } catch (e) {
    return(e);
  }
}

export const getCategories = async (page: number, limit: number, group: 'materials' | 'other-resources') => {
  try {
    const res = await axios.get(`${BASE_PATH}/categories?page=${page}&limit=${limit}&group=${group}`, {
      headers:{
        Authorization: `Bearer ${Cookies.get(ACCESS_TOKEN)}`
      }
    });
    return res.data;
  } catch (e) {
    throw(e);
  }
}

export const getCategory = async (id: string) => {
  try {
    const res = await axios.get(`${BASE_PATH}/categories/${id}`, {
      headers:{
        Authorization: `Bearer ${Cookies.get(ACCESS_TOKEN)}`
      }
    });
    return res.data;
  } catch (e) {
    throw(e);
  }
}

export const getSubjects = async (page: number, limit: number, category: string) => {
  try {
    const res = await axios.get(`${BASE_PATH}/subjects?page=${page}&limit=${limit}&category=${category}`, {});
    return res.data;
  } catch (e) {
    throw(e);
  }
}

export const getSubject = async (id: string) => {
  try {
    const res = await axios.get(`${BASE_PATH}/subjects/${id}`, {
      headers:{
        Authorization: `Bearer ${Cookies.get(ACCESS_TOKEN)}`
      }
    });
    return res.data;
  } catch (e) {
    throw(e);
  }
}

export const getMaterials = async (userId: string, params: {
  category: string,
  subject: string,
  published: boolean,
}) => {
  try {
    const res = await axios.get(`${BASE_PATH}/users/${userId}/materials`, {
      headers:{
        Authorization: `Bearer ${Cookies.get(ACCESS_TOKEN)}`
      },
      params
    });
      return res.data;
  } catch (e) {
      return e;
  }
}

export const getMaterial = async (userId: string, materialId: string) => {
  try {
    const res = await axios.get(`${BASE_PATH}/users/${userId}/materials/${materialId}`, {
      headers:{
        Authorization: `Bearer ${Cookies.get(ACCESS_TOKEN)}`
      }
    });
    return res.data;
  } catch (e) {
    return e;
  }
}

export const getOtherResources = async (userId: string, params: {
  category: string,
  subject: string,
  published: boolean,
}) => {
  try {
    const res = await axios.get(`${BASE_PATH}/users/${userId}/other-resources`, {
      headers:{
        Authorization: `Bearer ${Cookies.get(ACCESS_TOKEN)}`
      },
      params
    });
      return res.data;
  } catch (e) {
      return e;
  }
}

export const getOtherResource = async (userId: string, otherResourceId: string) => {
  try {
    const res = await axios.get(`${BASE_PATH}/other-resources/${otherResourceId}`, {
      headers:{
        Authorization: `Bearer ${Cookies.get(ACCESS_TOKEN)}`
      }
    });
      return res.data;
  } catch (e) {
      return e;
  }
}

export const getSubscriptions = async (userId: string) => {
  try {
    const res = await axios.get(`${BASE_PATH}/users/${userId}/subscriptions`, {
      headers:{
        Authorization: `Bearer ${Cookies.get(ACCESS_TOKEN)}`
      }
    });
      return res.data;
  } catch (e) {
      return e;
  }
}

export const getUserCourseware = async (userId: string) => {
  try {
    const res = await axios.get(`${BASE_PATH}/users/${userId}/courseware`, {
      headers:{
        Authorization: `Bearer ${Cookies.get(ACCESS_TOKEN)}`
      }
    });
      return res.data;
  } catch (e) {
      return e;
  }
}

export const getUserCoursewares = async (userId: string) => {
  try {
    const res = await axios.get(`${BASE_PATH}/users/${userId}/coursewares`, {
      headers:{
        Authorization: `Bearer ${Cookies.get(ACCESS_TOKEN)}`
      }
    });
      return res.data;
  } catch (e) {
      return e;
  }
}