import React from 'react';
import Dialog from '../commons/Dialog';
import Text from '../commons/Text';
import Select from '../commons/Select';
import Button from '../commons/Button';

type Props = {
  courses: Array<string>;
  subjects: Array<string>;
  onSelectCourse: (value: string) => void;
  onSelectSubject: (subject: string) => void;
  disabledCourses?: boolean;
  disabledSubjects?: boolean; 
  visible: boolean;
  onDismiss: () => void;
  onSumbit: () => void;
  disabledOnSubmit?: boolean;
}

export default (props: Props) => {
  return (
    <Dialog
      visible={props.visible}
      onClose={props.onDismiss}
    >
      <div>
        <Text
          variant='title'
          style={{
            textAlign: 'center',
          }}
        >
          Help us to improve your overall experience
        </Text>
        <Text
          variant='subtitle'
          style={{
            textAlign: 'center',
            marginTop: 20,
          }}
        >
          Please select your course and <br/> specialization below.
        </Text>
        <Select
          label={'Course'}
          options={props.courses}
          onSelect={props.onSelectCourse}
          disabled={props.disabledCourses}
        />

        <Select
          label={'Specialization'}
          options={props.subjects}
          onSelect={props.onSelectSubject}
          disabled={props.disabledSubjects}
        />
        
        <Button
          variant='primary'
          title="Submit"
          onButtonPress={() => props.onSumbit()}
          disabled={props.disabledOnSubmit}
        />
        
      </div>
    </Dialog>
  )
}


const styles: {[key: string]: React.CSSProperties} = {
  container: {}
}