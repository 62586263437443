import React, { useState } from 'react';
import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
  BrowserRouter,
} from "react-router-dom";
// @ts-ignore
import Cookies from 'js-cookie';
import logo from './logo.svg';
import './App.css';
import {
  useAuth
} from './providers/AuthProvider';


// Pages
import Login from './pages/Login';
import Register from './pages/Register';
import Onboarding from './pages/Onboarding';
import Home from './pages/Home';
import LectureCategories from './pages/LectureCategories';
import LectureSubjects from './pages/LectureSubjects';
import Lecture from './pages/Lecture';

import AuthProvider from './providers/AuthProvider';
import { ACCESS_TOKEN } from './utils/constants';
import Lectures from './pages/Lectures';
import OtherResourcesCategories from './pages/OtherResourcesCategories';
import OtherResourcesSubjects from './pages/OtherResourcesSubjects';
import OtherResources from './pages/OtherResources';
import OtherResource from './pages/OtherResource';
import Account from './pages/Account';
import Profile from './pages/Profile';
import Courseware from './pages/Courseware';


function App() {
  return (
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route
              path="/onboarding"
              element={
                <RequireAuth>
                  <Onboarding />
                </RequireAuth>
              }
            />
            <Route
              path="/"
              element={ <Navigate to="/home" /> }
            />
            <Route
              path="/home"
              element={
                <RequireAuth>
                  <Home />
                </RequireAuth>
              }
            />
            <Route
              path="/lecture-categories"
              element={
                <RequireAuth>
                  <LectureCategories />
                </RequireAuth>
              }
            />
            <Route
              path="/lecture-categories/:category/subjects"
              element={
                <RequireAuth>
                  <LectureSubjects />
                </RequireAuth>
              }
            />
            <Route
              path="/lecture-categories/:category/subjects/:subject"
              element={
                <RequireAuth>
                  <Lectures />
                </RequireAuth>
              }
            />
            <Route
              path="/lecture-categories/:category/subjects/:subject/materials/:material"
              element={
                <RequireAuth>
                  <Lecture />
                </RequireAuth>
              }
            />
            <Route
              path="/other-resources"
              element={
                <RequireAuth>
                  <OtherResourcesCategories />
                </RequireAuth>
              }
            />
            <Route
              path="/other-resources/:category/subjects"
              element={
                <RequireAuth>
                  <OtherResourcesSubjects />
                </RequireAuth>
              }
            />
            <Route
              path="/other-resources/:category/subjects/:subject"
              element={
                <RequireAuth>
                  <OtherResources />
                </RequireAuth>
              }
            />
            <Route
              path="/other-resources/:category/subjects/:subject/other-resources/:otherResource"
              element={
                <RequireAuth>
                  <OtherResource />
                </RequireAuth>
              }
            />
            <Route
              path="/account"
              element={
                <RequireAuth>
                  <Account />
                </RequireAuth>
              }
            />
            <Route
              path="/account/profile"
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />
            <Route
              path="/account/courseware"
              element={
                <RequireAuth>
                  <Courseware />
                </RequireAuth>
              }
            />
            {/* <Route path="/lectures" element={<LectureCategories />} /> */}
            {/* <Route path="/lectures/:category" element={<Lectures />} /> */}
          </Routes>
        </AuthProvider>
      </BrowserRouter>
  );
}

function RequireAuth({ children }: { children: JSX.Element }) {
  
  const token = Cookies.get(ACCESS_TOKEN);

  let location = useLocation();

  if (!token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export default App;
