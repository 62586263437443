import React, { useState, useCallback, useEffect, useContext } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import TextInput from '../components/commons/TextInput';
import Button from '../components/commons/Button';
import {
  mustBeValidEmail,
  mustNotBeEmptyOrSpace
} from '../utils/validator';
import { useNavigate } from "react-router-dom";
import {
  login,
} from '../utils/api';
import {
  throttle
} from 'lodash';
import { useAuth } from '../providers/AuthProvider';
// @ts-ignore
import Cookies from 'js-cookie';
import { ACCESS_TOKEN } from '../utils/constants';
import {
  useMediaQuery
} from 'react-responsive';
import Text from '../components/commons/Text';

type Inputs = {
  email: string
  password: string
}

const Login = () => {

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>();

  const navigate = useNavigate();
  const isBigScreen = useMediaQuery({ minWidth: 768 });
  const [registrationError, setRegistrationError] = useState<string>('');

  
  const onSubmit: SubmitHandler<Inputs> = useCallback(throttle(async (data) => {
    try {
      const response = await login(data.email, data.password);
      Cookies.set(ACCESS_TOKEN, response.token);
      navigate('/');
    } catch (e: any) {
      switch(e.response.status) {
        case 404: 
          setRegistrationError('Email / Password is incorrect');
          break; 

        default: 
          setRegistrationError('Unknown error occured, please try again');
      }
    }
  }, 2000, { trailing: true }), []);


  
  return (
    <div 
      style={Object.assign({}, styles.background)}
    >
      <div
        style={Object.assign({}, styles.formContainer, isBigScreen && styles.formContainerDesktop)}
      >
        <img 
          src={require('../assets/met-logo.png')}
          style={styles.logo}
        />
        <form 
          style={styles.form}
        >
            { registrationError && (
              <Text
                variant="subtitle"
                style={{
                  textAlign: 'center',
                  color: 'red'
                }}
              >
                {registrationError}
              </Text>
            )}

          <TextInput
            style={{
              marginTop: 20,
            }}
            textProps={{
              ...register(
                "email", { 
                  validate: {
                    mustNotBeEmptyOrSpace,
                    mustBeValidEmail
                  }
                },
              ),
              placeholder: 'Email',
              type: 'email',  
            }}
            error={errors.email?.message}
          />

          <TextInput
            style={{
              marginTop: 20,
            }}
            textProps={{
              ...register(
                "password", { 
                  validate: {
                    mustNotBeEmptyOrSpace,
                  }
                },
              ),
              placeholder: 'Password',
              type:"password"
            }}
            error={errors.password?.message}
          />
          <div
            style={styles.btnForgotPassword}
          >
            <p>Forgot Password</p>
          </div>
          <div
            style={styles.btnSections}
          >
            <Button 
              style={{
                flex: 1,
              }}
              title='Login'
              onButtonPress={handleSubmit(onSubmit)}
            />
            <div 
              style={{ marginLeft: 12, marginRight: 12}}
            />
            <Button 
              style={{
                flex: 1,
              }}
              title='Register'
              onButtonPress={() => {
                navigate('/register');
              }}
            />
          </div>
        </form>
      </div>
    </div>
  )
}

const styles: {[key: string]: React.CSSProperties}  = {
  background: {
    // border: '1px solid red',
    minHeight: '100svh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  backgroundDesktop: {
    flexDirection: 'row',
  },
  logo: {
    objectFit: 'contain',
    width: '100%',
    height: 100
  },
  formContainer: {
    width: '100%'
  },
  formContainerDesktop: {
    width: 600
  },
  form: {
    // border: '1px solid red',
    paddingRight: 38,
    paddingLeft: 38,
  },
  btnForgotPassword: {
    textAlign: 'center',
    color: '#0066FF'
  },
  btnSections: {
    flexDirection: 'row',
    display: 'flex',
    marginTop: 20,
  }
};

export default Login;
