export default {
  textInput: {
    fontSize: 16,
  },
  button: {
    fontSize: 16,
    fontWeight: '700'
  },
  title: {
    fontSize: 20,
    fontWeight: '700'
  },
  subtitle: {
    fontSize: 18
  }
}