import React, { useEffect, useState } from 'react';
import BottomNavigation from '../components/commons/BottomNavigation';
import Text from '../components/commons/Text';
import colors from '../constants/colors';
import { getCategories, getCategory, getSubjects, getSubject, me } from '../utils/api';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  useMediaQuery
} from 'react-responsive';
import SideNavigation from '../components/commons/SideNavigation';
import { SPECIALIZATION_ID } from '../utils/constants';


const categoryIcon = require('../assets/icons/category.png');
const videoLibraryIcon = require('../assets/icons/video-library.png');

type Props = {

}

export default (props: Props) => {
  const isBigScreen = useMediaQuery({ minWidth: 768 });
  const navigate = useNavigate();
  let location = useLocation();

  const {
    category
  } = useParams();
  

  const [subjects, setSubjects] = useState([{
    _id: '',
    title: ''
  }]);

  const [categoryData, setCategoryData] = useState<any>({});

  const init = async () => {
    // if (category) {
    //   const categoryRes = await getCategory(category);
    //   setCategoryData(categoryRes);
      
    //   const data = await getSubjects(1, 100, category);
    //   setSubjects(data.docs);
    // }
    if (!category) {
      return navigate('/home');
    }

    const user = await me();
    const categoryRes = await getCategory(category);
    setCategoryData(categoryRes);


    if (category === SPECIALIZATION_ID) {
      const data = await getSubject(user.specializedSubject);
      setSubjects([data]);  
    } else {
      const data = await getSubjects(1, 100, category);
      setSubjects(data.docs);    
    }
  }

  useEffect(() => {
    init();
  }, [])

  return (
    <div
      style={Object.assign({}, styles.root, isBigScreen && styles.rootBigScreen)}
    >

      { isBigScreen &&
        (
          <div
            style={styles.sideNav}
          >
            <SideNavigation />
          </div>
        )
      }

      <div
        style={Object.assign({}, styles.container, isBigScreen && styles.containerBigScreen)}
      >
        <div
          style={styles.heading}
        >
          <div style={styles.logoContainer}>
            <img src={require('../assets/met-logo.png')}  style={styles.logo} />
          </div>
        </div>

        <div style={styles.body}>
          <div
            style={styles.categoryContainer}
          >
            <img
              src={videoLibraryIcon}
              style={styles.categoryIcon}
            />
            <Text
              variant='title'
              style={{
                color: colors.white
              }}
            >
            {categoryData.title}
            </Text>
          </div>
          <div style={styles.content}>
            {subjects.map((subject) => (
              <div
                key={subject._id}
                style={styles.item}
                onClick={() => {
                  navigate(location.pathname + "/" + subject._id);
                }}
              >
                <img src={categoryIcon} 
                  style={styles.itemIcon}
                />
                <Text 
                  variant={'title'}
                >
                    {subject.title} 
                </Text>
              </div>
            ))}
          </div>
        </div>

        {!isBigScreen &&  (
          <div style={styles.footer}>
            <BottomNavigation />
          </div>
        )}  
      </div>
    </div>
  )
}

const styles: {[key: string]: React.CSSProperties} =  {
  root: {
    display: 'flex',
    flexDirection: 'column',
    background: '#f4f4f4'
  },
  rootBigScreen: {
    flexDirection: 'row',
  },
  sideNav: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexGrow: 1,
    paddingTop: 20,
    background: colors.white,
    maxWidth: 300,
  },
  container: {
    // border: '1px solid green',
    minHeight: '100svh',
    maxHeight: '100svh',
    display: 'flex',
    flexDirection: 'column',
    flex: 3,
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 20, 
    paddingRight: 20,
  
  },
  containerBigScreen: {
    margin: '0 auto',
    maxWidth: 768,
  },
  heading: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: 20,
    paddingBottom: 20,
  },
  logoContainer: {
    height: 72,
  },
  logo: {
    objectFit: 'contain',
    width: '100%',
    height: 'inherit',
  },
  categoryContainer: {
    // border: '1px solid red',
    borderRadius: 12,
    background: '#0039CC',
    paddingBottom: 22,
    paddingTop: 22,
    paddingRight: 25,
    paddingLeft: 25,
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  categoryIcon: {
    height: 45,
    objectFit: 'contain',
    marginRight: 20,
  },
  body: {
    // border: '1px solid red',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    paddingLeft: 20, 
    paddingRight: 20,
    overflow: 'hidden',
    width: '100%',
  },
  content: {
    // display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 'inherit',
    overflow: 'scroll',
    overflowX: 'hidden'
  },
  item: { 
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 24,
    paddingBottom: 24,
    paddingRight: 20,
    paddingLeft: 20,
    background: '#E2EFFB',
    borderRadius: 12,
    marginBottom: 10,
  },
  itemIcon: {
    height: 50,
    width: 50,
    marginRight: 14,
  },
  footer: {
    // border: '1px solid red',
    width: '100%',
    background: colors.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
  }
}